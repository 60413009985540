import React from 'react';
import './sidebar.scss';
import { GeneralInfo } from '../../molecules';
import { LanguageNav, SidebarActions } from '../../atoms';

function Sidebar( props ){
    return(
        <div className="sidebar">
            <GeneralInfo />
            <SidebarActions />
            <LanguageNav />
        </div>
    )
}

export default Sidebar;