import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next/hooks';
import './contentcontainer.scss';
import { ContentFormation, ContentLanguages, ContentExperience, ContentToolsTech } from '../';
import ContentContext from '../../../contexts/ContentContext';
import UiContext from '../../../contexts/UiContext';


export const ContentContainer = (props) => {
    const [t, i18n] = useTranslation();
    const contentContext = useContext( ContentContext );
    const uiContext = useContext( UiContext );

    const ttLables = {
        most_used: t("Most Used"),
        known: t("Known")
    }

    useEffect(() => {
        i18n.on('languageChanged', function(lng) {
            contentContext.setLoading();
            contentContext.getData();
        })

        contentContext.getData();
    }, []);

    useEffect(() =>{
        if(contentContext.state.requestError){
            uiContext.openMessageModal(true,  t('Error fetching data'), t('Please check if you have a network connection and refresh the page'), 'fas fa-wifi');
        }
    }, [contentContext.state.requestError]);

    return(
        <div className={'content-container ' + ( contentContext.state.loading ? 'content-loading' : '') }>
            <ContentFormation data={ contentContext.state.formations } loading={ contentContext.state.loading } sectionTitle={ t('Academic Formation') }  />
            <ContentLanguages data={ contentContext.state.languages } loading={ contentContext.state.loading } sectionTitle={ t('Languages') }  />
            <ContentExperience data={ contentContext.state.experience } loading={ contentContext.state.loading } sectionTitle={ t('Professional Experience') }  />
            <ContentToolsTech data={ contentContext.state.tools_and_tech } loading={ contentContext.state.loading } sectionTitle={ t('Tools and Technologies') } labels={ ttLables }  />
        </div>
    )
}