import React, { useEffect, useContext, useState, Fragment } from 'react';
import './messagemodal.scss';
import { useTranslation } from 'react-i18next/hooks';
import UiContext from '../../../contexts/UiContext';

export const MessageModal = ( props ) =>{
    const [t] = useTranslation();
    const uiContext = useContext(UiContext);
    const { title, content, icon } = uiContext.state.messageModal;

    useEffect(() => {
        var modal =  document.getElementById('message_modal');
        if(uiContext.state.messageModal.show){
            modal.style.display = 'flex';
            setTimeout(function(){
                modal.classList.add('active');
            }, 10)
        }else{
            modal.classList.remove('active');
            setTimeout(function(){
                modal.style.display = 'none';
            }, 400)
        }
    }, [uiContext.state.messageModal.show]);

    const closeModal = () =>{
        return uiContext.openMessageModal(false, title, content, icon);
    }

    return(
        <div className="message-modal" id="message_modal">
            <div className="modal-content">
                <span className="close-modal" onClick={ () => { closeModal() } }><i className="fas fa-times"></i></span>
                <span className="modal-icon"><i className={ icon }></i></span>
                <div className="modal-content-container">
                    <h2 className="title">{ title }</h2>
                    <p className="message-content">{ content }</p>
                </div>
            </div>
            <div className="modal-overlay" onClick={ () => { closeModal() } }></div>
        </div>
    )
}