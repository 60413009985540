import React, { useEffect } from 'react';
import './sidebaractions.scss';

export const SidebarActions = (props) =>{

    function getPrintLink(link){
        let groupExtract = /https?\:\/\/(?:www{0,3})?\.?([A-Za-z\.\/]+)(\/)?/g.exec(link)[1];
        const lastChar = groupExtract[groupExtract.length - 1];
        
        if(lastChar === '/'){
            groupExtract = groupExtract.substring(0, groupExtract.length - 1); 
        } 

        return groupExtract;
    };

    
    useEffect(() =>{
        const links = document.querySelectorAll('.link-action');
        
        for(let i=0; i<links.length; i++){
            const elem = links[i].children[0];
            const elemHref = elem.getAttribute('href');

            elem.setAttribute( 'data-print-link', getPrintLink(elemHref) );
        }

    }, []);

    getPrintLink('http://www.andrereitz.com.br');

    return(
        <ul className="sidebar-actions">
            <li className="hide-print" onClick={ () => window.print() } ><i className="fas fa-print"></i></li>
            <li className="link-action">
                <a href="https://www.linkedin.com/in/reitz/" target="_blank" rel="noopener noreferrer" data-print-link=''>
                    <i className="fab fa-linkedin-in"></i>
                </a>
            </li>
            <li className="link-action">
                <a href="https://www.andrereitz.com.br" target="_blank" rel="noopener noreferrer" data-print-link=''>
                    <i className="fas fa-globe-americas"></i>
                </a>
            </li>
        </ul>
    )
}