import React, { useContext } from 'react';
import './profilepicture.scss';
import UiContext from '../../../contexts/UiContext';

export const ProfilePicture = (props) =>{

    const uiContext = useContext(UiContext);

    function GetPicture(){
        const {photo} = uiContext.state;
        
        if(photo) return <ThePicture photo={photo} />;

        return <LoadingInfo />;
    }

    function LoadingInfo(){
        return(
            <div className="loading"> <div className="rtz-loader"><div className="spinner"></div></div> </div>
        )
    }

    function ThePicture(props){
        return(
            <img alt="Profile" src={props.photo} />
        )
    }

    return(
        <div className="profile-picture">
            <GetPicture />
        </div>
    )
}
