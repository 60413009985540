import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next/hooks';
import './contactinfo.scss';
import PrivateContext from '../../../contexts/PrivateContext';
import UiContext from '../../../contexts/UiContext';


const ContactInfo = () =>{
    const [t, i18n] = useTranslation();
    const pvtContext = useContext(PrivateContext);
    const uiContext = useContext(UiContext);

    useEffect(() =>{
        i18n.on('languageChanged', function(lng) {
            pvtContext.getData();
        })
    }, []);

    useEffect(() => {

        pvtContext.getData();

    }, [pvtContext.state.token])
    
    function GeneralContent(){
        const { phone } = pvtContext.state;
        
        if( phone == null ) return <LoadingPlaceholder />;
        if( phone === 'locked' ) return <LockedInfo />;
        return <UnlockedInfo />;
    }

    function LoadingInfo(){
        return(
            <div className="general-information">
                <div className="loading side-box"> <div className="rtz-loader"><div className="spinner"></div></div></div>
            </div>
        )
    }

    function LoadingPlaceholder(){
        return(
            <div className="general-information">
                <li className="placeholder"> <span className="animated-background-dark"></span> </li>
                <li className="placeholder"> <span className="animated-background-dark"></span> </li>
                <li className="placeholder"> <span className="animated-background-dark"></span> </li>
            </div>
        )
    }
    
    function UnlockedInfo(){
        return(
            <ul className="general-information">
                <li className="info-location"><i className="fas fa-map-marker-alt"></i>  { pvtContext.state.location } </li>
                <li className="info-email"><i className="fas fa-envelope"></i> { pvtContext.state.email } </li>
                <li className="info-phone"><i className="fas fa-phone-alt"></i> { pvtContext.state.phone } </li>
            </ul>
        )
    }
    
    function LockedInfo(){
        return(
            <div className="general-information">
                <span className="unlock-private button-primary side-box hide-print" onClick={ () => { uiContext.openTokenModal() } }><i className="fas fa-lock"></i> {t('Unlock Information')} </span>
            </div>
        )
    }
    
    return(
        <GeneralContent />
    )
}

export default ContactInfo;