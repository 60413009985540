import React, { Fragment } from 'react';
import './content.scss';
import { ContentContainer } from '../../molecules';
import { TokenModal, MessageModal } from '../../atoms';

export default function Content(props){
    return(
        <Fragment>
            <div className="content">
                <ContentContainer />
            </div>
            <TokenModal />
            <MessageModal />
        </Fragment>
    )
}