import React from 'react';
import './App.css';

// Contexts and Providers
import { UiProvider } from './contexts/UiContext';
import { ContentProvider } from './contexts/ContentContext';
import { PrivateProvider } from './contexts/PrivateContext';

// i18n Hook
import { useTranslation } from 'react-i18next/hooks';

// Components
import { Sidebar, Content } from './components/organisms';

function App() {
  const [t, i18n] = useTranslation();
  const changeLanguage = (lng) => {
    localStorage.setItem('language', lng);
    i18n.changeLanguage(lng);
  };
  
  return (
    <UiProvider>
      <ContentProvider>
        <PrivateProvider>
          <div className="App">

            <Sidebar />

            <Content />

          </div>
        </PrivateProvider>
      </ContentProvider>
    </UiProvider>
  );
}

export default App;
