import React from 'react';
import './profilename.scss';

export const ProfileName = (props) =>{
    const { displayName, tagLine } = props;

    function ProfileInfo(){
        // return <LoadingPlaceholder />;
        if( !displayName || !tagLine ) return <LoadingPlaceholder />

        return <ProfileData />
    }

    function LoadingPlaceholder(){
        return(
            <div className="profile-name placeholder">
                <h1 className="animated-background-dark"></h1>
                <h3 className="animated-background-dark"></h3>
            </div>
        )
    }

    function ProfileData(){
        return(
            <div className="profile-name">
                <h1>{ displayName }</h1>
                <h3>{ tagLine }</h3>
            </div>
        )
    }

    return(
        <ProfileInfo />
    )
}