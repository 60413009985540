import React, { useContext } from 'react';
import './contentformation.scss'
import { SectionBadge, SectionTitle } from '../../atoms';
import ContentContext from '../../../contexts/ContentContext';

export const ContentFormation = (props) =>{
    const { loading, data, sectionTitle } = props;
    const contentContext = useContext( ContentContext );

    function GetComponent(){
        if( loading ) return <LoadingPlaceholder />;
        
        return <Component />;
    }

    function LoadingPlaceholder(){
        return(
            <div className="content-section formation loading">
                <div className="title">
                    <SectionBadge icon={ contentContext.state.icons.formation } loading={ true } />
                    <SectionTitle loading={true} />
                </div>
                <div className="section-content">
                    <h3>
                        <div className="placeholder">
                            <span className="animated-background"></span>
                        </div>
                    </h3>
                    <p className="bullet">
                        <span className="placeholder">
                            <span className="animated-background"></span>
                        </span>
                    </p>
                    <p>
                        <span className="placeholder">
                            <span className="animated-background"></span>
                        </span>
                    </p>
                </div>
            </div>
        )
    }

    function Component(){
        return(
            <div className="content-section formation">
                <div className="title">
                    <SectionBadge icon={ contentContext.state.icons.formation } loading={ false } />
                    <SectionTitle title={ sectionTitle } loading={ false } />
                </div>
                <div className="section-content">
                    {
                        data && data.length > 0 &&
                        data.map( function(item, i){
                            return(
                                <div className="item" key={i}>
                                    <h3>{ item.title }</h3>
                                    <p className="bullet">{ item.school }</p>
                                    <p>{ item.year }</p>
                                </div>
                            )
                        } )
                    }
                </div>
            </div>
        )
    }

    return(
        <GetComponent />
    )
}