import React, { useContext } from 'react';
import './contentlanguages.scss';
import ContentContext from '../../../contexts/ContentContext';
import { SectionBadge, SectionTitle } from '../../atoms';

export const ContentLanguages = ( props ) =>{
    const { loading, data, sectionTitle } = props;
    const contentContext = useContext( ContentContext );
    const loadingPlaceholders = ["item1", "item2", "item3"];

    function GetComponent(){
        if( loading ) return <LoadingPlaceholder />;
        
        return <Component />;
    }

    function LoadingPlaceholder(){
        return(
            <div className="content-section languages">
                <div className="title">
                    <SectionBadge icon={ contentContext.state.icons.languages } loading={ true } />
                    <SectionTitle title={ sectionTitle } loading={ true } />
                </div>
                <div className="section-content">
                    {
                        loadingPlaceholders.map( function(item, i){
                            return(
                                <div className="item" key={ i }>
                                    <h3>
                                        <span className="placeholder">
                                            <span className="animated-background"></span>
                                        </span>
                                    </h3>
                                    <p>
                                        <span className="placeholder">
                                            <span className="animated-background"></span>
                                        </span>
                                    </p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    function Component(){
        return(
            <div className="content-section languages">
                <div className="title">
                    <SectionBadge icon={ contentContext.state.icons.languages } loading={ false } />
                    <SectionTitle title={ sectionTitle } loading={ false } />
                </div>
                <div className="section-content">
                    {
                        data && data.length > 0 &&
                        data.map( function(item, i){
                            return(
                                <div className="item" key={i}>
                                    <h3>{ item.language }</h3>
                                    <p>{ item.level }</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }


    return(
        <GetComponent />
    )
}