import React from 'react';
import axios from 'axios';

const UiContext = React.createContext();

class UiProvider extends React.Component {
    app = this;

    state = {
        diplayName: null,
        tagLine: null,
        tokenModal: false,
        messageModal: {
            show: false,
            title: null,
            content: null,
            icon: null
        }
    }

    getLanguage = () =>{
        var lang = localStorage.getItem('language');

        if( lang === 'pt' ) return 'pt_BR';

        return 'en_US';
    }

    openTokenModal = (show = true) => {
        if(show){
            this.setState({ tokenModal: true });
            return;
        }

        this.setState({ tokenModal: false });
        return;
    }

    openMessageModal = (show = true, title, content, icon) => {
        const app = this;

        if(show){
            this.setState({ 
                messageModal: {
                    show: true,
                    title,
                    content,
                    icon
                } 
            });

            return;
        }

        this.setState({
            messageModal: {
                show: false,
                title,
                content,
                icon
            }
        });

        return;
    }

    getProfileData = () =>{
        axios.get(process.env.REACT_APP_BASE_API + '/'+ this.getLanguage())
            .then(res => {
                this.setState({ 
                    photo: res.data.photo,
                    displayName: res.data.display_name,
                    tagLine: res.data.tagline
                });
            })
    }

    render(){
        const { children } = this.props;
        const state = this.state;
        const getProfileData = this.getProfileData;
        const openTokenModal = this.openTokenModal;
        const openMessageModal = this.openMessageModal;

        return(
            <UiContext.Provider
                value={{
                    state,
                    getProfileData,
                    openTokenModal,
                    openMessageModal
                }}
            >
                {children}
            </UiContext.Provider>
        )
    }

}



export { UiProvider };
export const UiConsumer = UiContext.UiConsumer;

export default UiContext;