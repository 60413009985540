import React from 'react';
import axios from 'axios';

const ContentContext = React.createContext();

class ContentProvider extends React.Component {

    state = {
        formations: null,
        languages: null,
        experience: null,
        tools_and_tech: null,
        loading: true,
        requestError: false,
        icons: {
            formation: 'fas fa-graduation-cap',
            languages: 'fas fa-globe',
            experience: 'fas fa-briefcase',
            tools_and_tech: 'fas fa-microchip'
        }
    }

    getLanguage = () =>{
        var lang = localStorage.getItem('language');

        if( lang === 'pt' ) return 'pt_BR';

        return 'en_US';
    }

    setLoading = () =>{
        this.setState({ loading: true });
    }

    getData = () =>{
        this.setState({
            loading: true,
            requestError: false
        })
        axios.get( process.env.REACT_APP_BASE_API + '/'+ this.getLanguage() +'/content' )
            .then( res => {
                this.setState({ 
                    loading: false,
                    formations: res.data.formations,
                    languages: res.data.languages,
                    experience: res.data.experience,
                    tools_and_tech: res.data.tools_and_tech,
                });
            })
            .catch(err => {
                console.log('error', err);
                this.setState({
                    requestError: true
                })
            })
    }

    render(){
        const { children } = this.props;
        const state = this.state;

        return(
            <ContentContext.Provider
                value={{
                    state,
                    getData: this.getData,
                    setLoading: this.setLoading
                }}
            >
                {children}
            </ContentContext.Provider>
        )
    }
}

export { ContentProvider };
export const ContentConsumer = ContentContext.ContentConsumer;

export default ContentContext;