import React, { useEffect, useContext, useState, Fragment } from 'react';
import './tokenmodal.scss';
import { useTranslation } from 'react-i18next/hooks';
import PrivateContext from '../../../contexts/PrivateContext';
import UiContext from '../../../contexts/UiContext';

export const TokenModal = ( props ) =>{
    const [t] = useTranslation();
    const pvtContext = useContext(PrivateContext);
    const uiContext = useContext(UiContext);

    const [tokenState, setTokenState] = useState('');
    const [tokenMsg, setTokenMsg] = useState('');
    const [formClass, setFormClass] = useState('');
    const [firstToken, setFirstToken] = useState(false);
    const contextToken = pvtContext.state.token;

    function formSubmit(e){
        e.preventDefault();

        setFirstToken(true);
        pvtContext.setToken(tokenState);
        pvtContext.getData();
    }

    function setFormToken(e){
        setTokenState(e.currentTarget.value);
    }

    const LoadingMessage = () =>{
        return(
            <Fragment>
                <span className="rtz-loader">
                    <span className="spinner"></span>
                </span>
    
                {tokenMsg}
            </Fragment>
        )
    }
    const FinalMessage = () =>{
        return(
            <Fragment>
                <i className={'fas ' + (formClass === 'invalid' ? 'fa-times' : 'fa-check') } ></i>
    
                {tokenMsg}
            </Fragment>
        )
    }
    const GetMessage = () => {
        if( tokenMsg === t('Validating token...') ){
            return <LoadingMessage />;
        }

        return <FinalMessage />;
    }

    useEffect(() => {
        var modal =  document.getElementById('token_form');
        if(uiContext.state.tokenModal){
            modal.style.display = 'flex';
            setTimeout(function(){
                document.getElementById('token_form').classList.add('active');
            }, 10)
        }else{
            document.getElementById('token_form').classList.remove('active');
            setTimeout(function(){
                modal.style.display = 'none';
            }, 400)
        }
    }, [uiContext.state.tokenModal])

    useEffect(() => {
        if(!firstToken){
            return setFormClass('hidden');
        }
        if( !pvtContext.state.tokenValidation ){
            setFormClass('hidden');
        }else if( pvtContext.state.tokenValidation === 'invalid' ){
            setFormClass('invalid');
            setTokenMsg( t('Invalid Token!') )
        }else if( pvtContext.state.tokenValidation === 'checking' ){
            setFormClass('checking');
            setTokenMsg( t('Validating token...') );
        }else{
            setFormClass('valid');
            setTokenMsg( t('Token is Valid!') )
            setTimeout(function(){
                uiContext.openTokenModal(false);
            }, 1000)
        }
    }, [pvtContext.state.tokenValidation])

    return(
        <div className="token-modal" id="token_form">
            <div className="modal-content">
                <span className="close-modal" onClick={ () => { uiContext.openTokenModal(false) } }><i className="fas fa-times"></i></span>
                <p className="intro">
                    { t('To access private information please validate with the provided token') }
                </p>
                <form onSubmit={ (e) => formSubmit(e) } className={ formClass }>
                    <input type="text" 
                        className="user-token" 
                        placeholder={ pvtContext.state.token !== 'unset' ? pvtContext.state.token : 'Insert your token'} 
                        onChange={ (e) => setFormToken(e) } />
                    <div className="actions">
                        <button className="button-primary"><i className="fas fa-lock-open"></i> { t('Validate') } </button>
                    </div>
                </form>
                <p className={ 'msg-description ' + formClass }>
                    <GetMessage />
                </p>
            </div>
            <div className="modal-overlay" onClick={ () => { uiContext.openTokenModal(false) } }></div>
        </div>
    )
}