import React from 'react';
import './sectionbadge.scss';

export const SectionBadge = ( props ) =>{
    const { icon, loading } = props;

    function getClasses(){
        return 'section-icon ' + icon;
    }

    function GetBadge(){
        if( loading ) return <LoadingPlaceholder />

        return <TheBadge />
    }

    function LoadingPlaceholder(){
        return(
            <div className="section-icon">
                <div className="placeholder">
                    <span className="animated-background"></span>
                </div>
            </div>
        )
    }

    function TheBadge(){
        return(
            <span className={ getClasses() }></span>
        )
    }

    return(
        <GetBadge />
    )
}