import i18n from "i18next";
import { initReactI18next } from "react-i18next/hooks";

import translationEN from "./locales/en/translation.json";
import translationPT from "./locales/pt/translation.json";

function getLang(){
    const localStorageLang = localStorage.getItem('language');

    if(localStorageLang) return localStorageLang;

    localStorage.setItem('language', 'en');
    return 'en';
}

const resources = {
    en: {
        translation: translationEN
    },
    pt: {
        translation: translationPT
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: getLang(),

        keySeparator: false,

        interpolation: {
            escapeValue: false
        },
        
        useSuspense: false
    });

export default i18n;