import React, { useContext } from 'react';
import './contenttoolstech.scss';
import ContentContext from '../../../contexts/ContentContext';
import { SectionBadge, SectionTitle } from '../../atoms';

export const ContentToolsTech = ( props ) =>{
    const { loading, data, sectionTitle, labels } = props;
    const contentContext = useContext( ContentContext );
    const tagsPlaceholder = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    function GetComponent(){
        if( loading ) return <LoadingPlaceholder />;
        
        return <Component />;
    }

    function LoadingPlaceholder(){
        return(
            <div className="content-section tools-techs loading">
                <div className="title">
                    <SectionBadge icon={ contentContext.state.icons.tools_and_tech } loading={ true } />
                    <SectionTitle title={ sectionTitle } loading={ true } />
                </div>
                <div className="section-content">
                    <div className="item most-used">
                        <h3>
                            <span className="placeholder"><span className="animated-background"></span></span>
                        </h3>
                        <ul className="job-tt">
                            {   tagsPlaceholder &&
                                tagsPlaceholder.map(function(item, index){
                                    return <li key={'placeholder-most-used-'+index}><span className="placeholder"><span className="animated-background"></span></span></li>;
                                })
                            }
                        </ul>
                    </div>
                    <div className="item know">
                        <h3>
                            <span className="placeholder"><span className="animated-background"></span></span>
                        </h3>
                        <ul className="job-tt">
                            {   tagsPlaceholder &&
                                tagsPlaceholder.map(function(item, index){
                                    return <li key={'placeholder-know-'+index}><span className="placeholder"><span className="animated-background"></span></span></li>;
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }

    function Component(){
        return(
            <div className="content-section tools-techs">
                <div className="title">
                    <SectionBadge icon={ contentContext.state.icons.tools_and_tech } loading={ false } />
                    <SectionTitle title={ sectionTitle } loading={ false } />
                </div>
                <div className="section-content">
                    <div className="item most-used">
                        <h3>{ labels.most_used }</h3>
                        <ul className="job-tt">
                            {   data &&
                                data.most_used.map(function(item, index){
                                    return <li key={ 'tt-item-most-used-'+index }>{ item }</li>;
                                })
                            }
                        </ul>
                    </div>
                    <div className="item know">
                        <h3>{ labels.known }</h3>
                        <ul className="job-tt">
                            {   data &&
                                data.known.map(function(item, index){
                                    return <li key={ 'tt-item-know-'+index }>{ item }</li>;
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <GetComponent />
    )
}