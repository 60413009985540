import React from 'react';
import './sectiontitle.scss';

export const SectionTitle = ( props ) =>{
    const { title, loading } = props;

    function GetComponent(){
        if( loading ) return <LoadingPlaceholder />;

        return <Component />;
    }

    function LoadingPlaceholder(){
        return(
            <h2>
                <div className="placeholder">
                    <span className="animated-background"></span>
                </div>
            </h2>
        )
    }

    function Component(){
        return(
            <h2>{ title }</h2>
        )
    }

    return(
        <GetComponent />
    )
}