import React from 'react';
import axios from 'axios';

const PrivateContext = React.createContext();

class PrivateProvider extends React.Component {
    state = {
        token: 'unset',
        tokenValidation: null,
        location: null,
        email: null,
        phone: null
    }

    getLanguage = () =>{
        var lang = localStorage.getItem('language');

        if( lang === 'pt' ) return 'pt_BR';

        return 'en_US';
    }

    getAPIToken = () =>{
        const storageToken = localStorage.getItem('api_token');

        if(storageToken) return storageToken;
        
        return 'unset';
    }

    setAPIToken = ( token, remove = false ) =>{
        if( remove ){
            return localStorage.removeItem('api_token');
        }

        return localStorage.setItem('api_token', token);
    }

    getData = (token = null) =>{
        this.setState({
            tokenValidation: 'checking',
            location: null,
            email: null,
            phone: null
        });

        if( token ){
            this.setAPIToken( token );
        }

        axios.get(process.env.REACT_APP_BASE_API + '/' + this.getLanguage() + '/private/' + this.getAPIToken())
            .then( res => {
                this.setState({
                    token: this.getAPIToken(),
                    tokenValidation: 'valid',
                    location: res.data.location,
                    email: res.data.email,
                    phone: res.data.phone
                });
            })
            .catch(err => {
                if( !err.response ){
                    console.log('server error');
                    return false;
                }else{
                    this.setAPIToken( token, true );
                }

                if(err.response.status === 400){
                    this.setState({
                        tokenValidation: 'invalid',
                        location: 'locked',
                        email: 'locked',
                        phone: 'locked'
                    });
                }else{
                    console.log('Request error', err);
                }
            })
    };

    render(){
        const { children } = this.props;
        const state = this.state;

        return(
            <PrivateContext.Provider
                value={{
                    state,
                    getData: this.getData,
                    setToken: this.setAPIToken
                }}
            >
                {children}
            </PrivateContext.Provider>
        )
    }

}



export { PrivateProvider };
export const PrivateConsumer = PrivateContext.PrivateConsumer;

export default PrivateContext;