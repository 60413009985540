import React, { Fragment, useContext, useEffect } from 'react';
import { ProfilePicture, ProfileName, ContactInfo } from '../../atoms';
import UiContext from '../../../contexts/UiContext';

const GeneralInfo = (props) =>{
    const uiContext = useContext(UiContext);
    const { photo, displayName, tagLine } = uiContext.state;

    useEffect(() => {
        uiContext.getProfileData();
    }, [photo, displayName, tagLine]);

    return(
        <Fragment>
            <ProfilePicture />
            <ProfileName displayName={uiContext.state.displayName} tagLine={uiContext.state.tagLine} />
            <ContactInfo />
        </Fragment>
    )
}

export default GeneralInfo;