import React, { useEffect } from 'react';
import './languagenav.scss';
import { useTranslation } from 'react-i18next/hooks';

export const LanguageNav = (props) =>{
    const [t, i18n] = useTranslation();

    function checkActiveLang(){
        const langs = document.querySelectorAll('.lang ');
        const currentLang = localStorage.getItem('language');

        for( let i=0; i<langs.length; i++){
            let currentClasses = langs[i].classList;
            currentClasses.remove('active');

            if( currentClasses.contains( 'lang-'+currentLang ) ){
                currentClasses.add('active');
            }
        }
    }

    const changeLanguage = (event, lng) => {
        if(event.currentTarget.classList.contains('active')) return;

        localStorage.setItem('language', lng);
        i18n.changeLanguage(lng);

        checkActiveLang();
    };
    
    useEffect(() => {
        i18n.on('languageChanged', function(lng) {
            checkActiveLang();
        });

        checkActiveLang();
    })

    return(
        <ul className="language-nav hide-print">
            <li className="lang lang-en" onClick={(e) => changeLanguage(e, 'en')}>English</li>
            <li className="lang lang-pt" onClick={(e) => changeLanguage(e, 'pt')}>Português</li>
        </ul>
    )
}