import React, { useContext } from 'react';
import './contentexperience.scss';
import ContentContext from '../../../contexts/ContentContext';
import { SectionBadge, SectionTitle } from '../../atoms';

export const ContentExperience = ( props ) =>{
    const { loading, data, sectionTitle } = props;
    const contentContext = useContext( ContentContext );
    const loadingPlaceholders = [1, 2, 3];
    const tagPlaceholders = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

    function GetComponent(){
        if( loading ) return <LoadingPlaceholder />;
        
        return <Component />;
    }

    function LoadingPlaceholder(){
        return(
            <div className="content-section experience loading">
                <div className="title">
                    <SectionBadge icon={ contentContext.state.icons.languages } loading={ true } />
                    <SectionTitle title={ sectionTitle } loading={ true } />
                </div>
                <div className="section-content">
                    {   loadingPlaceholders && loadingPlaceholders.length > 0 &&
                        loadingPlaceholders.map( function(item, i){
                                return(
                                    <div className="item" key={i}>
                                        <div className="experience-title">
                                            <h3 className="bullet"><span className="placeholder"><span className="animated-background"></span></span></h3> 
                                            <p className="company"><span className="placeholder"><span className="animated-background"></span></span></p>
                                            <div className="experience-dates">
                                                <p className="date date-from"><span className="placeholder"><span className="animated-background"></span></span></p>
                                                <span className="date-separator">/</span>
                                                <p className="date date-to"><span className="placeholder"><span className="animated-background"></span></span></p>
                                            </div>
                                        </div>
                                        <p className="description">
                                            <span className="placeholder"><span className="animated-background"></span></span>
                                        </p>
                                        <p className="description">
                                            <span className="placeholder"><span className="animated-background"></span></span>
                                        </p>
                                        <p className="description line-end">
                                            <span className="placeholder"><span className="animated-background"></span></span>
                                        </p>
                                        <ul className="job-tt">
                                            { tagPlaceholders &&
                                                tagPlaceholders.map( function(tt, i){
                                                    return <li key={ 'tag-'+tt } ><span className="placeholder"><span className="animated-background"></span></span></li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                )
                            })
                    }
                </div>
            </div>
        )
    }

    function Component(){
        return(
            <div className="content-section experience">
                <div className="title">
                    <SectionBadge icon={ contentContext.state.icons.experience } loading={ false } />
                    <SectionTitle title={ sectionTitle } loading={ false } />
                </div>
                <div className="section-content">
                    {   data && data.length > 0 &&
                        data.map( function(item, i){
                            return(
                                <div className="item" key={i}>
                                    <div className="experience-title">
                                        <h3 className="bullet">{ item.position }</h3> 
                                        <p className="company">{ item.company }</p>
                                        <div className="experience-dates">
                                            <p className="date date-from">{ item.date_from  }</p>
                                            <span className="date-separator">/</span>
                                            { !item.is_current &&
                                                <p className="date date-to">{ item.date_to }</p>
                                            }
                                            { item.is_current &&
                                                <p className="date date-to">Present</p>
                                            }
                                        </div>
                                    </div>
                                    <p className="description">{ item.description }</p>
                                    <ul className="job-tt">
                                        { item.tools_tech &&
                                            item.tools_tech.map( function(tt, i){
                                                return <li key={ 'tag-'+tt } >{ tt }</li>
                                            })
                                        }
                                    </ul>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }


    return(
        <GetComponent />
    )
}